<template>
  <article class="article">
    <a
      :href="`${baseURL}/${publication.handle}`"
      class="image-link"
      target="_blank"
    >
      <img
        class="article__thumb thumbnail"
        alt="Thumbnail"
        :src="
          `${baseURL}/rest${
            publication.bitstreams.find(
              bitstream => bitstream.bundleName == 'THUMBNAIL'
            ).retrieveLink
          }`
        "
      />
    </a>
    <div class="article__info">
      <h5 class="article__title">
        <a
          :href="`${baseURL}/${publication.handle}`"
          target="_blank"
          :title="publication.name"
          >{{ publication.name }}</a
        >
      </h5>
      <p class="article__abstract">
        {{
          publication.metadata.find(
            meta => meta.key == "dc.description.abstract"
          ).value
        }}
      </p>
      <Button
        theme="primary"
        :href="`${baseURL}/${publication.handle}`"
        target="_blank"
        >Seguir Leyendo</Button
      >
    </div>
  </article>
</template>
<script>
import Button from "@/components/ui/atoms/Button";
export default {
  components: {
    Button
  },
  props: {
    publication: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const baseURL = "https://repositorio.igp.gob.pe";

    return {
      baseURL
    };
  }
};
</script>
<style lang="scss" scoped>
.article {
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
  &__thumb {
    height: 130px;
    max-width: 95px;
    width: 95px;
  }
  &__title {
    margin-bottom: 0.5rem;
    a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__abstract {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0.8em;
  }
}
</style>
