<template>
  <section>
    <p>Revise nuestros más recientes artículos:</p>
    <br />
    <Publication
      v-for="(publication, index) in publications.data"
      :key="index"
      :publication="publication"
      class="item"
    />
    <Button tag="a" target="_blank" :href="`${baseURL}${collection_handle}`"
      >Ver todos nuestros artículos...</Button
    >
  </section>
</template>
<script>
import Publication from "@/components/common/Publication/Publication.vue";
import { ref, reactive } from "vue";
import Button from "@/components/ui/atoms/Button";
export default {
  components: {
    Publication,
    Button
  },
  props: {
    collection_id: {
      type: String,
      default: ""
    },
    collection_handle: {
      type: String,
      default: ""
    }
  },
  async setup(props) {
    const baseURL = "https://repositorio.igp.gob.pe/";
    const restURL = `${baseURL}rest/collections/`;
    let isLoading = ref(true);
    let publications = reactive({ data: {} });

    await fetch(
      `${restURL}${props.collection_id}/items?limit=5&expand=metadata,bitstreams`
    )
      .then(response => response.json())
      .then(data => {
        publications.data = data.sort(function(a, b) {
          return b.id - a.id;
        });
      });
    return {
      isLoading,
      publications,
      baseURL
    };
  }
};
</script>
<style lang="scss">
@use "@/assets/styles/settings/_variables.scss";
.item {
  margin-bottom: variables.$spacing;
  padding-bottom: variables.$spacing;
  border-bottom: 1px solid variables.$border-color;
}
</style>
